import validateTextInput from '../validation_utils/validateTextInput'
import validateSelect from '../validation_utils/validateSelect'

export default function authenticationRegistrationFormValidation () {
  const registrationForm = Array.from(document.getElementsByClassName('vjs-registration-form'))[0]

  if (registrationForm) {
    ['change', 'submit'].forEach(formEvent => {
      registrationForm.addEventListener(formEvent, event => {
        validateTextInput(event, 'vjs-user-account-attributes-first-name', setMissingRequiredFieldErrorText)
        validateTextInput(event, 'vjs-user-account-attributes-last-name', setMissingRequiredFieldErrorText)
        validateTextInput(event, 'vjs-user-password', setPasswordValidationErrorText)
        validateSelect(event, 'vjs-user-acquisition-channel', setMissingRequiredFieldErrorText)
        validateSelect(event, 'vjs-user-registration-driver', setMissingRequiredFieldErrorText)
        validateSelect(event, 'vjs-user-registration-vintage-affinity', setMissingRequiredFieldErrorText)
      })
    })
  }
}

function setMissingRequiredFieldErrorText (validityObject, errorEl) {
  const { valueMissing } = validityObject

  if (valueMissing) errorEl.textContent = 'This field is required'
}

function setPasswordValidationErrorText (validityObject, errorEl) {
  const { valueMissing, tooShort } = validityObject

  if (valueMissing) {
    errorEl.textContent = 'You need to enter a password'
  } else if (tooShort) {
    errorEl.textContent = 'This password is too short'
  }
}
