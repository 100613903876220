/* global ALGOLIASEARCH_APP_ID, ALGOLIASEARCH_SEARCH_KEY, QUERY_SUGGESTION_INDEX */
import algoliasearch from 'algoliasearch'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import {
  trackSearchPreviewClick,
  trackAutocompleteClick,
  trackAutocompleteOpen,
  trackNavbarSearch
} from '../../analytics/events/browsing'
import { getCookie } from '../../utils/cookieHelpers'

export default function searchAutocomplete () {
  const algoliaClient = algoliasearch(ALGOLIASEARCH_APP_ID, ALGOLIASEARCH_SEARCH_KEY)
  const placeholder = document.querySelector('#vjs-aa-placeholder')
  const placeholderMobile = document.querySelector('#vjs-aa-placeholder-mobile')
  if (!placeholder || !placeholderMobile) return

  const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 4,
    transformSource ({ source }) {
      return {
        ...source,
        onSelect ({ item }) {
          trackAutocompleteClick('recentQuery', null)
          trackSearchPreviewClick()
          trackNavbarSearch(item.label, false, true)
        },
        getItemUrl ({ item }) {
          return createUrl(item.label, item.category)
        },
        templates: {
          item (params) {
            const { item, html } = params
            return html`<a class="aa-ItemLink" href="${createUrl(item.label, item.category)}">
              ${source.templates.item(params).props.children}
            </a>`
          }
        }
      }
    }
  })

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient: algoliaClient,
    indexName: QUERY_SUGGESTION_INDEX,
    categoryAttribute: [
      'Listing_production',
      'facets',
      'exact_matches',
      'category_name'
    ],
    itemsWithCategories: 2,
    categoriesPerItem: 2,
    getSearchParams ({ state }) {
      return { hitsPerPage: state.query ? 5 : 9 }
    },
    transformSource ({ source }) {
      return {
        ...source,
        onSelect ({ item }) {
          trackSearchPreviewClick()
          trackAutocompleteClick('querySuggestion', null)
          trackNavbarSearch(item.query, true, false)
        },
        getItemUrl ({ item }) {
          return createUrl(item.query, item.__autocomplete_qsCategory)
        },
        templates: {
          item (params) {
            const { item, html } = params
            return html`<a class="aa-ItemLink" href="${createUrl(item.query, item.__autocomplete_qsCategory)}">
              ${source.templates.item(params).props.children}
            </a>`
          }
        }
      }
    }
  })

  placeholder.remove()
  placeholderMobile.remove()

  const searchInputs = ['#vjs-autocomplete-container', '#vjs-autocomplete-container-mobile']
  const autocompleteInstances = []

  searchInputs.forEach(searchInput => {
    const autocompleteInstance = autocomplete({
      container: searchInput,
      detachedMediaQuery: 'none',
      placeholder: 'Search Vinterior',
      insights: true,
      onStateChange ({ state }) {
        if (!window.hasFiredAutocompleteOpen) {
          if (state.isOpen) {
            window.hasFiredAutocompleteOpen = true
            trackAutocompleteOpen()
          }
        }
      },
      onSubmit ({ state, event }) {
        const query = state.query
        trackNavbarSearch(query, false, false)
        window.location.href = `/search?q=${encodeURIComponent(query)}`
      },
      plugins: [
        recentSearchesPlugin,
        querySuggestionsPlugin
      ],
      getSources ({ query, setContext }) {
        return [
          {
            sourceId: 'listings',
            getItems ({ query }) {
              const nodeEnv = process.env.NODE_ENV

              return getAlgoliaResults({
                searchClient: algoliaClient,
                queries: [
                  {
                    indexName: `Listing_${nodeEnv}`,
                    query,
                    params: {
                      userToken: getCookie('_ALGOLIA'),
                      hitsPerPage: query ? 5 : 10,
                      clickAnalytics: true,
                      analytics: true,
                      getRankingInfo: true
                    }
                  }
                ],
                transformResponse ({ results, hits }) {
                  if (results[0]?.index === 'Listing_production') {
                    setAlgoliaTestAllocation(setContext, results)
                  }

                  return hits
                }
              })
            },

            onSelect ({ item }) {
              trackAutocompleteClick('listing', item.objectID)
              trackSearchPreviewClick()
            },

            getItemUrl ({ item }) {
              return `https://www.vinterior.co/${item.path_hierarchy}/${item.slug}`
            },

            templates: {
              header ({ state, html }) {
                return headerTemplate(state, html)
              },

              noResults () {
                return 'No results.'
              },

              item ({ item, components, html }) {
                return itemTemplate(item, components, html)
              }
            }
          }
        ]
      },
      openOnFocus: true,
      navigator: {
        navigate ({ itemUrl }) {
          window.location.assign(itemUrl)
        },
        navigateNewTab ({ itemUrl }) {
          const windowReference = window.open(itemUrl, '_blank', 'noopener')

          if (windowReference) {
            windowReference.focus()
          }
        },
        navigateNewWindow ({ itemUrl }) {
          window.open(itemUrl, '_blank', 'noopener')
        }
      }
    })
    autocompleteInstances.push(autocompleteInstance)
  })

  const aaInputElements = Array.from(document.getElementsByClassName('aa-Input'))
  aaInputElements.forEach(inputEl => inputEl.addEventListener('blur', () => {
    autocompleteInstances.forEach(instance => instance.setIsOpen(false))
  }))
}

function setAlgoliaTestAllocation (setContext, results) {
  const abTestVariantID = results[0].abTestVariantID
  if (abTestVariantID) {
    setContext({
      abTestVariant: abTestVariantID
    })

    const expVersion = abTestVariantID === 1 ? 'control' : 'variant'
    window.dataLayer.push({
      event: 'ce - amplitude - setAlgoliaAllocations',
      user: {
        enabled_flipper_features: {
          algolia_generic_ab: expVersion
        }
      }
    })
  }
}

function headerTemplate (state, html) {
  const headerLabel = (state.context.abTestVariant === undefined || state.context.abTestVariant === 2) ? 'Recommended for you' : 'Recommended'

  return html`
    <div class="flex gap-1">
      <span class="aa-SourceHeaderTitle flex items-center gap-2 text-atlantic-green">${headerLabel}</span>
      <div class="aa-SourceHeaderLine opacity-20"></div>
    </div>
  `
}

function itemTemplate (item, components, html) {
  return html`
    <div class="aa-ItemWrapper">
      <a class="aa-ItemContent no-underline" href="https://www.vinterior.co/${item.path_hierarchy}/${item.slug}">
        <div class="aa-ItemIcon aa-ItemIcon--alignTop">
          <img src="${item.main_photo?.image?.thumb_webp?.url}" alt="${item.title}" />
        </div>
        <div class="aa-ItemContentBody">
          <div class="aa-ItemContentTitle">
            ${components.Highlight({ hit: item, attribute: 'title' })}
          </div>
        </div>
        <div class="aa-ItemActions">
          <button class="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly" type="button" title="Select">
            <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
              <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
            </svg>
          </button>
        </div>
      </a>
    </div>
  `
}

function createUrl (query, category) {
  const urlParams = new URLSearchParams()
  urlParams.set('q', query)
  if (category) {
    urlParams.set('hFR[category_hierarchy.lvl0][0]=', category)
  }

  return `/search?${urlParams.toString()}`
}
